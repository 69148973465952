import { useRef } from 'react'
import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import PageContainer from '@/Components/layout/helpers/PageContainer'
import ProgramSetsAll from '@/Components/tables/programs/ProgramSetsAll'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

function ProgramIndex() {
  const setModal = useSetRecoilState(modalState)
  const auth = useAuth()
  const programsTableRef = useRef(null)

  usePageGuard({
    auth,
    permission: 'view-program',
  })

  useTitle('All programs')

  const refreshProgramsTable = () => {
    if (programsTableRef.current) {
      programsTableRef.current.refresh()
    }
  }

  const showProgramSetModal = () => {
    setModal({
      name: 'set',
      data: { onProgramSave: refreshProgramsTable },
    })
  }

  const showProgramModal = () => {
    setModal({
      name: 'program',
      data: {
        onSave: () => {
          refreshProgramsTable()
        },
      },
    })
  }

  return (
    <PageContainer contained>
      <div className="mb-5 grid grid-cols-2 text-3xl font-light text-slate-900">
        <div>
          All <span className="font-medium">programs</span>
        </div>

        <PermissionGuard permission="create-program" auth={auth}>
          <div className="grid grid-flow-col justify-items-end gap-2.5 text-right @md:mt-0">
            <div className="hidden flex-wrap justify-end gap-3 md:flex">
              <Anchor
                style={{ minWidth: '200px' }}
                onClick={showProgramSetModal}
              >
                Add program set
              </Anchor>

              <Anchor
                style={{ minWidth: '200px' }}
                onClick={showProgramModal}
              >
                Add program
              </Anchor>
            </div>
            <div className="grid grid-flow-col gap-3 md:hidden">
              <RoundedIconButton
                icon="plus"
                className="bg-dark"
                onClick={showProgramSetModal}
              />
              <RoundedIconButton
                icon="plus"
                onClick={showProgramModal}
              />
            </div>
          </div>
        </PermissionGuard>
      </div>

      <PermissionGuard permission="view-program" auth={auth}>
        <ProgramSetsAll ref={programsTableRef} />
      </PermissionGuard>
    </PageContainer>
  )
}

export default ProgramIndex

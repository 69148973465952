import * as Sentry from '@sentry/react'
import { filter, head, isEmpty, isUndefined } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import HUISelect from '@/Components/headless/form/Select'
import InputOutputsByProgram from '@/Components/tables/InputOutputsByProgram'
import Tabs from '@/Components/Tabs'
import { pageAlertState } from '@/Config/Atoms/General'
import Conditions from '@/Pages/program/manage/Conditions'
import Fertigation from '@/Pages/program/manage/Fertigation'
import General from '@/Pages/program/manage/General'
import { standardActions, subscribe, unsubscribe } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import usePageGuard from '@/Utilities/usePageGuard'
import useTitle from '@/Utilities/useTitle'

const PageContainer = styled.div`
  padding: 0 24px;
  width: 100%;
`

const programState = atom({
  key: 'programData',
  default: {},
})

const programSiteState = atom({
  key: 'programSite',
  default: {},
})

const tabs = [
  {
    title: 'General',
    key: 'general',
  },
  {
    title: 'I/Os',
    key: 'inputOutputs',
    permission: 'view-input-output',
  },
  {
    title: 'Conditions',
    key: 'conditions',
    permission: 'view-condition',
  },
  {
    title: 'Fertigation',
    key: 'fertigation',
  },
]

function ProgramManage() {
  const apiClient = useApiClient()
  const auth = useAuth()

  const [program, setProgram] = useRecoilState(programState)
  const [programSite, setProgramSite] = useRecoilState(programSiteState)
  const [selectedTab, setSelectedTab] = useState([])
  const [siteArea, setSiteArea] = useState({})
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const setAlert = useSetRecoilState(pageAlertState)

  const { setSubscriberModelMap } = useEventSubscriber(['program', 'programSet'], standardActions, () => {
    getProgram()
  })

  usePageGuard({
    auth,
    permission: 'view-program',
  })

  useTitle(['Managing program', program?.name])

  const urlParams = useParams()

  const markers = useMemo(() => {
    if (!isEmpty(programSite)) {
      return [{
        id: `siteId#${programSite.id}`,
        position: {
          lat: parseFloat(programSite.lat),
          lng: parseFloat(programSite.lng),
        },
        title: programSite.name,
        // TODO: Marker fillColor
        fillColor: null,
        draggable: false,
      }]
    }
  }, [programSite, program])

  const getProgram = useCallback(async () => {
    try {
      let { data } = await apiClient.get(`/program/view/${urlParams.id}`)

      if (data && data.success) {
        const programData = formatKeys(data.program, 'camel')

        setProgram(programData)
        setSubscriberModelMap({
          program: [programData.id],
          programSet: [programData.programSet.id],
        })
      }
    } catch (error) {
      navigate('/404')
      Sentry.captureException(error)
    }
  }, [
    navigate,
    setProgram,
    urlParams.id,
  ])

  const availableTabs = useMemo(() => {
    return filter(tabs, (tab) => {
      return isUndefined(tab.permission)
        ? true
        : auth.can(tab.permission)
    })
  })

  useEffect(() => {
    getProgram()

    if (routerLocation.state && routerLocation.state.showAlert) {
      setAlert({
        type: 'success',
        content: routerLocation.state.message,
      })
    }
  }, [
    getProgram,
    urlParams,
    routerLocation,
    navigate,
    setAlert,
  ])

  useEffect(() => {
    if (!isEmpty(program)) {
      const getSite = async () => {
        try {
          let { data } = await apiClient.get(`/site/view/${program.programSet.siteId}?with[]=area`)

          if (data && data.success) {
            setProgramSite(data.site)
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      getSite()
    }
  }, [program])

  useEffect(() => {
    if (!isEmpty(programSite) && (!programSite.lat || !programSite.lng)) {
      const getCoordinates = async () => {
        try {
          let { data } = await apiClient.get(`/google/maps/search/text?query=${programSite.area.address}`)

          if (data.success) {
            setSiteArea(data.result.geometry.location)
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      getCoordinates()
    }
  }, [
    markers,
    programSite,
    setSiteArea,
  ])

  useEffect(() => {
    const programUpdated = () => {
      getProgram()
    }

    subscribe('program.updated', programUpdated)

    return () => {
      unsubscribe('program.updated', programUpdated)
    }
  }, [program])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  useEffect(() => {
    if (!selectedTab?.length) {
      setSelectedTab('general')
    }
  }, [setSelectedTab])

  return (
    <PageContainer>
      <div className="mb-4 grid grid-cols-1 items-center gap-4 @md:grid-cols-2">
        <div className="text-3xl font-light text-slate-900">
          Managing <span className="font-medium">{program.name}</span>
        </div>

        <div className="hidden @md:block @md:text-right">
          <Tabs
            defaultTab="general"
            tabs={availableTabs}
            onChange={tabChange}
          />
        </div>

        <div className="block md:hidden">
          <HUISelect
            className="mt-3 w-full rounded-md"
            defaultValue={head(availableTabs)}
            options={availableTabs}
            labelProperty="title"
            onChange={(selected) => {
              if (selected.key !== selectedTab) {
                setSelectedTab(selected.key)
              }
            }}
          />
        </div>
      </div>

      {!isEmpty(program) && selectedTab === 'general' && (
        <div className="row">
          <General
            program={program}
            siteArea={siteArea}
            getProgram={getProgram}
            markers={markers}
            programSite={programSite}
          />
        </div>
      )}

      {selectedTab === 'inputOutputs' && (
        <PermissionGuard permission="view-input-output" auth={auth}>
          <InputOutputsByProgram programId={program.id} program={program} getProgram={getProgram}/>
        </PermissionGuard>
      )}

      {selectedTab === 'conditions' && (
        <PermissionGuard permission="view-condition" auth={auth}>
          <Conditions program={program} />
        </PermissionGuard>
      )}

      {selectedTab === 'fertigation' && (
        <Fertigation program={program} />
      )}
    </PageContainer>
  )
}

export default ProgramManage

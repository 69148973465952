import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { debounce,
  forEach,
  head,
  isEmpty,
  map,
  reverse,
  sortBy,
  sumBy,
  toLower,
  truncate, uniqBy,
  upperFirst } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import SearchInput from '@/Components/common/input/SearchInput'
import TableHeader from '@/Components/common/table/Header'
import TypeSearch from '@/Components/common/TypeSearch'
import DropdownList from '@/Components/DropdownList'
import HUISelect from '@/Components/headless/form/Select'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import Tabs from '@/Components/Tabs'
import { modalState } from '@/Config/Atoms/General'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

function SitesAll(props) {
  const {
    extraParameters,
    basicSearch,
  } = props

  // Utilities
  const auth = useAuth()
  const navigate = useNavigate()
  const apiClient = useApiClient()

  // Internal component state
  const [tableData, setTableData] = useState(null)
  const [siteStats, setSiteStats] = useState(null)
  const [lastQuery, setLastQuery] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })
  const [selectedTab, setSelectedTab] = useState(null)
  const setModal = useSetRecoilState(modalState)

  // Events
  useEventSubscriber(['site'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      setLastQuery({
        pageIndex,
        pageSize,
        filters,
      })

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['searchType', filters?.search?.type || ''],
        ['search', filters?.search?.value || ''],
        ['status', !isEmpty(filters?.tab) ? filters?.tab : ''],
        ['with[]', 'area'],
        ['with[]', 'programSets.programs.operationalStatuses'],
      ])

      if (extraParameters) {
        forEach(extraParameters, (param) => {
          query.append(param[0], param[1])
        })
      }

      try {
        const { data } = await apiClient.get(`/site/query?${query}`)

        let siteData = map(data.sites.data, (site) => {
          const operationalStatuses = site.program_sets.map((set) => {
            return set.operational_statuses
          })
          const flattenedStatuses = [].concat(...operationalStatuses)

          const statuses = uniqBy(
            map(flattenedStatuses, (status) => {
              return {
                key: status.key,
                title: status.title,
                color: status.color,
                count: sumBy(flattenedStatuses, (s) => {
                  return s.key === status.key ? 1 : 0
                }),
              }
            }),
            'key',
          )

          return {
            id: site.id,
            rtuId: site.rtu_id,
            name: (
              <div className="flex items-center">
                <div>
                  <Tippy
                    content={`${site.active ? 'Successfully' : 'Not yet'} activated with the FEP`}
                    theme="light"
                    placement="right">
                    <span className="mr-4 p-1">
                      <span className={classNames({
                        'bg-green-600': site.active,
                        'bg-yellow-500': !site.active,
                      }, 'w-3 h-3 inline-block rounded-full')}></span>
                    </span>
                  </Tippy>
                </div>

                <div>
                  <div>
                    {site.name}

                    {site.has_alarms && (
                      <AlarmIcon type="site" uuid={site.uuid} />
                    )}
                  </div>

                  {site.description ? (
                    <Tippy
                      content={site.description}
                      delay={200}
                      theme="light"
                      disabled={site.description.length < 50}
                      placement="right">
                      <div className="inline-block text-xs text-slate-400">
                        {truncate(site.description, { length: 50 })}
                      </div>
                    </Tippy>
                  ) : null}
                </div>
              </div>
            ),
            status: !isEmpty(statuses) ? (
              map(statuses, (status, index) => {
                return (
                  <Pill color={status.color} key={index}>
                    {status.count} {status.title}
                  </Pill>
                )
              })
            ) : '-',
            area: site.area.name,
            communicationType: (
              <>
                <div>
                  {upperFirst(toLower(site.link_type))}
                </div>

                <div>
                  {site.link_type === 'LINE' ? site.line_link_id : `${site.radio_link_id} / ${site.radio_zone}`}
                </div>
              </>
            ),
            programCount: (
              <Pill color="#1570ef">
                {sumBy(site.program_sets, (programSet) => {
                  return programSet.programs.length
                })}
              </Pill>
            ),
            action: (
              <DropdownList
                icon={<ActionIcon/>}
                options={[
                  {
                    label: 'Site overview',
                    onClick: () => {
                      navigate(`/site/overview/${site.id}`)
                    },
                  },
                  {
                    label: 'Edit site',
                    disabled: !auth.can('update-site'),
                    onClick: () => {
                      setModal({
                        name: 'site',
                        data: {
                          site: formatKeys(site, 'camel'),
                          isEditing: true,
                        },
                      })
                    },
                  },
                  {
                    label: 'Manage site',
                    disabled: !auth.can('view-site'),
                    onClick: () => {
                      navigate(`/site/manage/${site.id}`)
                    },
                  },
                  {
                    label: 'Add program set',
                    topLine: true,
                    disabled: !auth.can('create-program'),
                    onClick: () => {
                      setModal({
                        name: 'set',
                        data: {
                          site: {
                            id: site.id,
                            name: site.name,
                          },
                        },
                      })
                    },
                  },
                ]}
              />
            ),
          }
        })

        let tableData = {
          ...data.sites,
          data: siteData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '20%',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: '15%',
        },
        {
          Header: 'Area',
          accessor: 'area',
          width: '10%',
        },
        {
          Header: 'Site ID',
          accessor: 'rtuId',
          width: '5%',
        },
        {
          Header: 'Communication Type',
          accessor: 'communicationType',
          width: '5%',
        },
        {
          Header: 'Programs',
          accessor: 'programCount',
          width: '5%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const searchTypes = useMemo(() => {
    return [{
      value: 'site',
      label: 'Site',
    }, {
      value: 'area',
      label: 'Area',
    }]
  }, [])

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Alarms',
        key: 'alarms',
      },
      {
        title: 'Stopped',
        key: 'stopped',
      },
      {
        title: 'Running',
        key: 'running',
      },
    ]
  }, [])

  const getHeaderPills = useMemo(() => {
    let pills = []

    if (tableData && siteStats) {
      pills.push({
        title: `${tableData.total} Total`,
        color: '#175CD3',
      })

      forEach(siteStats, (siteStat) => {
        pills.push({
          title: `${siteStat.count} ${siteStat.title}`,
          color: siteStat.color,
        })
      })
    }

    return pills
  }, [tableData, siteStats])

  const getSiteStats = useCallback(async () => {
    try {
      let { data: siteStats } = await apiClient.get('/site/stats')

      return reverse(sortBy(siteStats, 'count'))
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  useEffect(() => {
    (async () => {
      const siteStats = await getSiteStats()

      setSiteStats(siteStats)
    })()
  }, [setSiteStats, getSiteStats])

  return (
    <>
      {
        tableData ?
          <>
            <TableBasic
              testId="tableSitesAll"
              mainTable={true}
              columns={tableColumns}
              data={tableData}
              loading={tableDataLoading}
              getTableData={getTableDataStart}
              filterTabs={filterTabs}
              searchTypes={searchTypes}
              searchTerm={tableSearchTerm}
              selectedTab={selectedTab}
              headerPills={getHeaderPills}
              header={
                <TableHeader testId="tableSitesAllTableHeader">
                  <TableHeader.Title>Sites</TableHeader.Title>

                  {getHeaderPills?.length && (
                    <TableHeader.Pills className="mt-2 justify-self-start md:mt-0 md:ml-3 md:inline-block md:justify-self-start">
                      <PillWrapper>
                        {map(getHeaderPills, (pill, index) => {
                          return (
                            <Pill color={pill.color} key={index}>{pill.title}</Pill>
                          )
                        })}
                      </PillWrapper>
                    </TableHeader.Pills>
                  )}

                  <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center">
                    <div className="hidden md:inline-block">
                      <Tabs
                        tabs={filterTabs}
                        onChange={(selected) => {
                          if (selected !== selectedTab) {
                            setSelectedTab(selected)
                          }
                        }}
                        className="hidden md:inline-flex"
                      />
                    </div>

                    <div className="block md:hidden">
                      <HUISelect
                        className="mt-3 w-full rounded-md"
                        defaultValue={head(filterTabs)}
                        options={filterTabs}
                        labelProperty="title"
                        onChange={(selected) => {
                          if (selected.key !== selectedTab) {
                            setSelectedTab(selected.key)
                          }
                        }}
                      />
                    </div>

                    <TableHeader.Toolbar className="grid grid-flow-row gap-0 md:grid-flow-col">
                      {!basicSearch && (
                        <TypeSearch
                          types={searchTypes}
                          search={tableSearchTerm}
                          onChange={(searchTerm) => {
                            return setTableSearchTerm(searchTerm)
                          }}
                        />
                      )}

                      {basicSearch && (
                        <SearchInput
                          className="flex w-full flex-wrap"
                          term={tableSearchTerm}
                          onTermUpdate={
                            (value) => {
                              return setTableSearchTerm({
                                ...tableSearchTerm,
                                value,
                              })
                            }
                          }
                        />
                      )}
                    </TableHeader.Toolbar>
                  </TableHeader.SubHeader>
                </TableHeader>
              }
            />
          </> : <SkeletonTable />
      }
    </>
  )
}

export default SitesAll
